@import "../SendMessage/SendMessage.scss";

.actions-wrapper {
    display: flex;
    gap: 13px;
}

.btn {
    cursor: pointer;
    display: flex;
    height: 36px;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: EYInterstate;
    font-size: 14px;
    padding: 7px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;

    &_primary {
        width: 129px;
        background: #FFE600;
        color: var(--grey-500, #2E2E38);
    }

    &_secondary {
        width: 80px;
        color: #FFF;
        border: 1px solid #FFF;
        background: #1A1A24;
    }
}

.edit-mode {
    & input {
        background: transparent;
        border: none;
        color: #2e2e38;
        padding: 8px 0px;
        width: 100%;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: EYInterstate;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 133.333% */
        margin-bottom: 20px;
    }
}