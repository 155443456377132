@import "../../styles/variables.module.scss";
@import "../../styles/mixins";

.chatbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0px;
  height: 100%;

  &__text-area-container {
    padding: 8px 0 0 0;
    width: 100%;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
  }
  &__text-area-container-wchat {
    padding: 8px 0 0 0;
    padding-top: 0px;
    width: calc(100% - 110px);
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    align-self: center;
    padding-left: 40px;
  }
  &__text-area {
    width: 100%;
    resize: none;
    overflow: hidden;
    padding: 8px 36px 8px 1.25rem;
    color: var(--grey-600, #23232f);
    font-family: EYInterstate;
    border-radius: 4px;
    border: 2px solid var(--grey-300, #c4c4cd);
    background: var(--primary-white, #fff);
    box-shadow: 0px 5px 10px 0px rgba(26, 26, 36, 0.05);
  }

  &__text-area:focus-visible {
    outline: #2e2e3c auto 0px;
    color: var(--grey-600, #23232f);
  }

  &__send-container {
    padding: 0px 0px 0px 8px;
  }

  &__label {
    color: #1a1a24;
    font-weight: bold;
    padding-left: 8px;
  }

  &__label-dark {
    color: #fff;
    font-weight: 500;
    padding-left: 8px;
  }

  &__chat-img {
    width: 20px;
    height: 20px;
    display: flex;
    position: absolute;

    padding-left: 14px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  &__send-icon {
    width: 20px;
    height: 20px;
    display: flex;
    position: absolute;
    right: toRem(12px);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  &__char-limit {
    display: flex;
    color: #fff;
    justify-content: flex-end;
    width: 100%;
    font-size: 12px;
    margin-top: 3px;
  }
  &__char-limit-wchat {
    display: flex;
    color: #fff;
    justify-content: flex-end;
    width: 95%;
    font-size: 12px;
    margin-top: 3px;
  }
  &__clearbtn-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 8px;
    margin-top: -18px;
  }

  &__download-message {
    font-size: toRem(13px);
    color: #fff;
    margin: 0;
    display: flex;
  }
}
