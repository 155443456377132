@import "../../styles/variables.module.scss";
@import "../../styles/mixins";

.exapmles {
  &__queries {
    display: flex;
    gap: 18px;
    padding: 0px 34px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  &__query {
    border-radius: 8px;
    border: 1px solid var(--primary-grey-400747480, #747480);
    opacity: 0.7;
    background: var(--primary-grey-500-confident-grey-2-e-2-e-38, #2e2e38);
    text-align: center;
    display: flex;
    align-items: center;
    width: 200px;
    height: 50px;
    padding: 16px;
    color: var(--primary-ey-white-ffffff, #fff);
    text-align: center;
    font-family: EYInterstate;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
  }
}
