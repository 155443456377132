@import url("~@fortawesome/fontawesome-free/css/all.css");
@import "../src/styles/variables.module.scss";

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.col-6 {
  flex-basis: 50%;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(145, 158, 171, 0.6);
}
.text-center {
  text-align: center;
}
/* Common */
.break-word {
  word-wrap: break-word;
  /* must be applied globally for now, because of dynamic measuring in company list */
  .entity {
    &:not(:last-child) {
      margin-right: 10px;
    }
    &:not(:first-child)::before {
      content: ",";
      position: absolute;
      left: -10px;
    }
  }

  &:not(:first-child)::before {
    content: ",";
    position: absolute;
    left: -10px;
  }
}
.bg-cover {
  width: 100%;
  height: 100%;
  background-color: $grey-15;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
}
.ey-interstate {
  font-family: $font-interstate;
}
/* Icons */
[class$="-icon"] {
  font-style: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}
.header-icon {
  position: absolute;
  right: 15px;
}
$color1: $grey-3A;
$color2: $alternateblack;
$color3: $yellow;
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $dark-black;
  color: $white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.page-layout {
  padding: 21px;
  padding-top: 0px;
}
.bvKqMl,
#root,
.App {
  height: 100%;
}
#root {
  position: relative;
  z-index: 100;
}
.container {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
}
.col-1 {
  flex-basis: 8.333%;
}
.col-2 {
  flex-basis: 16.666%;
}
.col-3 {
  flex-basis: 25%;
}
.col-4 {
  flex-basis: 38.333%;
}
.col-5 {
  flex-basis: 41.666%;
}
.col-6 {
  flex-basis: 50%;
}
.col-7 {
  flex-basis: 58%;
}
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $color2;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: $color1;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color1;
}
.ie-react-model {
  border: 0 !important;
}
.ie-react-model:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.ie-modal-body {
  padding: 16px !important;
}
.ie-row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
}
.ReactModal__Overlay {
  z-index: 999 !important;
}
.ie-model-1 {
  .submit-modal {
    .yellow-btn {
      margin-left: 1rem;
      border: 1px solid;
    }
  }
}
.active .center-wrapper {
  color: $white;
}
.ReactModal__Overlay--after-open {
  background-color: rgba(26, 26, 36, 0.8) !important;
}
.ReactModal__Body--open {
  overflow-y: hidden;
}

.button-right {
  margin-left: 15px;
}
#mypopup {
  width: 400px;
  padding: 20px;
  font-family: Arial, sans-serif;
  font-size: 10pt;
  background-color: white;
  border-radius: 6px;
  position: absolute;
  display: none;
}
#mypopup::before {
  content: "";
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  background-color: white;
  position: absolute;
  left: -6px;
  top: 68px;
}

.center-wrapper {
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  // margin-top: 1rem;
}

.global-loder {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999999;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

html {
  overflow-x: hidden;
}
@for $i from 5 through 99 {
  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }
  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }
}

.silogoutmodal .advancedsearch-modal{
button {
  margin-right: 1rem;
}
.yellow-btn {
  background-color: #ffe600;
  border: 1px solid #ffe600;
  color: #000;
}

.secondary-btn {
  background: #0000;
  border: 1px solid #fff;
  color: #fff;
}
}
