@import "../../styles/variables.module.scss";
@import "../../styles/mixins";

.chatreply {
  @include justify-start;
  padding: toRem(4px) toRem(16px) toRem(8px) toRem(8px);
  padding-bottom: 5px;
  // margin: toRem(4px) toRem(16px);
  // margin: 0;
  // padding: toRem(4px) toRem(8px) toRem(8px) toRem(8px);
  &__source {
    padding: toRem(10px);
  }
  &__img-container {
    @include start-flex;
    margin-right: toRem(16px);
  }

  &__img-container-dark {
    @include start-flex;
    border-radius: toRem(2px);
    height: toRem(32px);
    padding: toRem(2px);
    background: $white;
    margin-right: toRem(16px);
  }

  &__resp-box {
    @include box-shadow2;
    @include break-word;
    @include start;
    // margin-left: toRem(16px);
    border-radius: 4px;
    border: 1px solid $gray;
    font-size: toRem(14px);
    color: #23232f;
    background: var(--primary-white, #fff);
    text-align: justify;
    width: 95%;
    flex-direction: column;
    // padding-left: 14px;
    padding-right: toRem(2px);
    &__resp {
      position: relative;
      padding: toRem(10px) toRem(15px);
      white-space: pre-wrap;
      width: calc(100% - 1.8rem);
      line-height: toRem(22px);
      border-bottom: 1px solid #e1e1e6;

      & table.content-table {
        margin-top: 20px;
        border-spacing: 0;
        border: 1px solid #e1e1e6;
        width: 73%;
        table-layout: auto;
        background-color: #ffffff;
        border-radius: 4px;
        border-collapse: separate;
        &__footer {
          text-align: right;
        }
        .files-download-btn {
          cursor: pointer;
          text-decoration: underline;
        }
        .files-slide-download-btn {
          // cursor: pointer;
        }

        th {
          padding: 5px 20px;
          // width: 40px;
          // // text-transform: capitalize;
          min-width: 100px;
          width: 60%;
        }
        tr {
          background: #e1e1e6;
        }
        th {
          border-bottom: 1px solid #e1e1e6;
          border-right: 1px solid #e1e1e6;
        }
        td {
          border-bottom: 1px solid #e1e1e6;
          border-right: 1px solid #e1e1e6;
          background: var(--primary-white, #fff);
        }

        td {
          padding: 5px 20px;
          text-align: left;
          vertical-align: top;
        }

        td.disclaimer {
          font-size: 11px;
        }
        td {
          .btn-show {
            color: #23232f;
            background: transparent;
            border: none;
            text-decoration: underline;
            align-items: right;
            cursor: pointer;
          }
        }
        td {
          .table__author {
            text-decoration: underline;
            color: var(
              --specific-use-colors-default-text-link-155-cb-4,
              #155cb4
            );
            cursor: pointer;
          }
        }
        td.table__footer {
          text-align: right;
        }
      }
    }
    &__btn-show {
      text-align: right;
    }
    &__footer {
      border-top: 1px solid #383843;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      padding: 6px 15px 9px 15px;
      width: calc(100% - 1.8rem);

      &__source-box {
        white-space: nowrap;
        height: 25px;
        display: flex;
        align-items: center;

        & p {
          margin: 0;
        }
      }

      &__links-box {
        margin-left: 10px;
        display: flex;
        gap: 10px;
        align-items: center;
        flex-wrap: wrap;
        overflow: hidden;

        &__source-link {
          // display: flex;
          align-items: center;
          color: #1a1a24;
          text-decoration: none;
          font-weight: 600;
          height: 19px;
          padding: 3px 5px;
          background: #cacacd;
          font-size: 12px;
          white-space: nowrap;
          // ellipsis changes for sources
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }

      &__links-box.slider {
        margin-left: 0;
        margin-top: 5px;
      }
    }

    &__footer.slider {
      flex-direction: column;
    }
  }

  &__resp-box.sidebar-open {
    width: 88%;
  }

  &__loader-container {
    @include left-flex;
    // @include box-shadow2;
    // margin-left: toRem(16px);
    border-radius: 4px;
    // background: $gray;
    max-width: 80%;
  }

  &__list {
    margin: 0;
  }

  &__list-row {
    list-style-type: square;
  }
}

.chatreply.sidebar-open {
  margin: 0;
  padding: toRem(4px) toRem(8px) toRem(8px) toRem(8px);
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.table-wrapper::-webkit-scrollbar {
  height: 5px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background: #c4cdd5;
  border-radius: 10px;
}
.table-row-slide {
  background: #747480;
  padding: 6px;
  display: flex;
  max-width: 185px;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
  cursor: pointer;
}
.file-type {
  width: 45px;
  height: 45px;
}
.chunk-loader {
  background-size: contain;
  background-repeat: no-repeat;
  height: 50px;
  width: 28px;
  padding-right: 6px;
  margin-bottom: -27px;
  display: inline-block;
  margin-left: 2px;
}
