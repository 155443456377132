@import "../../styles/variables.module.scss";
@import "../../styles/mixins";

.openaiquery {
  @include justify-start;
  padding: toRem(4px) toRem(16px) toRem(8px) toRem(8px);
  // margin: toRem(4px) toRem(16px);
  align-items: center;
  // margin: 0;
  // padding: toRem(4px) toRem(8px) toRem(8px) toRem(8px);

  &__query-box {
    color: #23232F; // color: $dark-black;
    background: var(--primary-white, #FFF); // background: $white;
    width: 100%;
    text-align: justify;
    @include box-shadow2;
    @include break-word;
    border-radius: toRem(4px);
    border: 1px solid #3a3a4a;
    font-size: toRem(15px);
    padding: toRem(10px) toRem(15px);
    position: relative;
  }

  &__img-container {
    @include start-flex;
    margin-right: toRem(16px);
  }

  &__img-container-dark {
    background: $white;
    @include start-flex;
    border-radius: toRem(2px);
    height: toRem(32px);
    padding: toRem(2px);
    margin-left: toRem(16px);
  }
}

.openaiquery.sidebar-open {
  margin: 0;
  padding: toRem(4px) toRem(8px) toRem(8px) toRem(8px);
}
