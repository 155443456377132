body {
  background: #1a1a24;
  font-family: "EYInterstate", "Segoe UI", sans-serif;
}

.download-btn-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 4px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.scrollable-area {
  min-height: 300px;
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
  /* border: 1px solid #747480; */
  padding-top: 2px;
  height: 100%;
}

.scrollable-area::-webkit-scrollbar {
  width: 10px;
  margin-left: 10px;
  padding-left: 10px;
}

.scrollable-area::-webkit-scrollbar-thumb {
  /* background-color: #2e2e3c; */
  background: #c4cdd5;
  border-radius: 10px;
  padding-right: 1rem;
}

.scrollable-area::-webkit-scrollbar-track {
  /* background: #c4cdd5; */
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* body::-webkit-scrollbar {
  display: none;
} */
@media screen and (min-width: 1500px) {
  .scrollable-area {
    max-height: 400px;
  }
}

@media screen and (min-width: 1600px) {
  .scrollable-area {
    max-height: 500px;
  }
}
