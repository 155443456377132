$gray: #747480;
$gray-2: #f6f6fa;
$alternate-gray: #c4c4cd;
$gray-6: #191a24;
$grey-15: #24242d;
$grey-3A: #3a3a4a;
$gray-15: #24242d;
$gray-50: #2e2e38;
$gray-40: #404854;
$gray-60: #c4cdd5;
$gray-30: #6e6e75;
$gray-70: #35374b;
$gray-100: #2e2e3c;
$gray-80: #383843;
$gray-90: #eaeaf2;
$lightgray: #f6f6fa;
$lightblack: #282833;
$dark-black: #1a1a24;
$black-3: #2e2e3c;
$black: #000;
$black-4: #2a2b39;
$light-brown: #333333;
$brown-2: #464653;
$alternateblack: #23232f;
$white: #fff;
$light-white: #d8d8d8;
$white-4: #f5f6f7;
$white-3: #4c4e5a;
$red-2: #ff9a91;
$teal-2: #93f0e6;
$orange-7: #bc2f00;
$yellow: #ffe600;
$yellow-2: #ffe601;
$green-4: #34c768;
$light-blue: #35a4e8;
$dark-blue: #542ea5;
$blue-2: #a0a0aa;
$white-dark: #ffffff;
$white-2: #ccc;
$tooltip-details: #dfe3e8;
$subsidiary-margin-left: 16.5px;
$threat-value-0: #4a4a57;
$form-control-black: #0c0c11;
$threat-value-1: #189d3e;
$threat-value-2: #57e188;
$threat-value-3: #ffb46a;
$threat-value-4: #ff9731;
$threat-value-5: #f95d54;
$solvencyCreditRatingColor: #ff736a;
$header-gray: #4a4a57;
$bg-tile: #24242d;
$network-node-unchecked: #3c3c42;
$network-node-na: #b0b0b8;
$chart-line-color: #979797;
$font-interstate: "EYInterstate", "Segoe UI", sans-serif;
$font-ey-NotoSansItalic: "NotoSansItalic", "Segoe UI", sans-serif;
$font-interstate-bold: "EYInterstateBold", "Segoe UI", sans-serif;
$ebitda-color: #60e6e1;
$ebit-color: $orange-7;
$grossProfitColor: #35a4e8;
$net-income-color: #ff9831;
$cash-color: #13652a;
$other-current-assets: #0d7575;
$net-PP-color: #1777cf;
$total-intangibles-color: #724bc3;
$other-long-term-assets-color: #3d108a;
$current-borrowings-color: #e0362c;
$other-current-liabilities-color: #ff9831;
$LT-debt-capital-leases-color: #35a4e8;
$quick-ratio-color: #ff736a;
$current-assets-color: #724bc3;
$current-liabilities-color: #b14891;
$accounts-receivable-color: #13652a;
$inventory-color: #168736;
$font-interstate: "EYInterstate", "Segoe UI", sans-serif;
$font-interstate-bold: "EYInterstateBold", "Segoe UI", sans-serif;
$font-noto-sans: "NotoSans", "Segoe UI", sans-serif;
$list-minimized-height: 354;
$modal-width: 800;
$ls-modal-width: 1000;
$logout-modal-width: 400;
$limit-restriction-modal-width: 620;
$modal-delete-portfolio-width: 586;
$download-modal-width: 420;
$discard-modal-width: 500;
$advanced-search-closing-modal-width: 500;
$save-report-modal-width: 520;
$delete-report-modal-width: 568;
$other-long-term-liabilities: #189d3e;
$table-cell-padding: 12;
$table-cell-border-color: #353541;
$file-upload-background-color: #2a2a32;
$file-upload-error: #ff4136;
$file-upload-success: #34c768;
$file-upload-inprogress: #ff810a;
$quickRatioCreditRatingColor: $orange-7;
$chart-axis-label-color: #fff;
$filterIconColor: #909090;
$total-Equity: #b14891;
$ebitdaCapexCreditRatingColor: #57e188;
$filterIconColor: #909090;
$filterIconColor: #909090;
$gray-70: #35374b;
$gray-3A: #3a3a4a;
$gray-2e: #2e2e37;
$gray-28: #282836;
$skyBlue: #188ce5;
$estimated-patents-still-active: #57e188;
$estimated-patents-inactive: #747480;
$patent-filings-alternate-row-color: #23232f;
$chart-solid-line-color: #d8d8da;
$yellow-1: #f5dc00;
$gray-1: lighten($gray, 20%);
$black-1: darken($brown-2, 25%);
$black-2: darken($brown-2, 20%);
$gray-5: #919eab;
$gray-10: darken(saturate($brown-2, 0.45), 5.88);
$green: #168736;
$green-1: #004f4f;
$green-2: #2db757;
$blue: #35a4e8;
$maroon: #922b73;
$purple: #724bc3;
$teal: #42c9c2;
$red: #f95d54;
$orange: #ff9831;
$orange-dark: darken($orange, 35%);
$orage-1: #ff972f;
$blue-10: #155cb4;
$color-background-base: $gray;
$color-background-dark: $gray-40;
$color-base: $color-background-base;
$font-ey: "EYInterstate", "Segoe UI", sans-serif;

$color-foreground-base: $white;
$color-foreground-dark: #24242d;
$color-foreground-light: #f4f6f8;

$color-border-base: $color-background-base;
$color-border-light: $color-foreground-base;
$color-border-darker: #c4cdd5;
$color-border-dark: $color-background-dark;

$color-font-base: $white;
$color-font-light: #808080;
$color-font-dark: #24242d;
$color-font-darker: $black;
// Container dimensions
$full-container-width: 100%;
// Bootstrap variables overwrite
$input-bg: $black-2;
$input-placeholder-color: $gray-40;
$input-placeholder-color: $gray-2;
$input-color: $color-font-base;
$input-border-color: darken($blue, 30%);
$input-focus-border-color: $yellow;
$input-focus-box-shadow: none;
$link-color: $gray-40;
$filter-input-background: #dfe3e9;
$filter-input-background-hover: #c4c4cd;
$table-cell-background: #e5e5e6;
$table-cell-background-hover: #1e1f31;
$disabled-tree-option: #62626b;
$grayCheck: #31302d;

// Entity Types
$company: $blue;
$industry: $purple;
$companytype: $maroon;
$geography: $teal;
$transactiontype: $yellow;
$specialism: $red;
$product: darken($purple, 20%);
$keyword: #c4c4cd;
$keyword: #747480;
$companystatus: $maroon;
$performanceindicators: $orange;

$answer-modules-grid-padding: 20px;
$sidebar-width: 240px;
$header-height: 185.2px;
$answer-modules-grid-width: calc(
  100% - (#{$sidebar-width} + #{$answer-modules-grid-padding})
);
$grid-gutter-width: 10px;
$transition-delay: 0.3s;
$dropdown-active-option-border: $yellow;
$quick-links-arrow: $yellow;
$sticky-height: 75px;
$sticky-wrapper-padding-top: $sticky-height;
$header-top-padding: 5px;
$custom-scrollbar-width: 6px;
$as-tree-struct-left-padding: 16px;
$as-tree-struct-width: 6px;

$font-family-primary: "EYInterstate", "Segoe UI", sans-serif;
$font-family-secondary: "NotoSans", "Segoe UI", sans-serif;

$resolution-lg-max: 1920px;
$resolution-md-max: 1800px;
$resolution-sm-max: 1000px;
$resolution-xs-max: 600px;
$primary: $yellow;

$map-max-color: #082c65;
$non-country-jurisdiction-label-color: #3e4652;
//HeatMap Color
$heat-map-1: #c7c7c7;
$heat-map-2: #1d9d31;
$heat-map-3: #21b218;
$heat-map-4: #f46b00;
$heat-map-5: #fd8500;
$heat-map-6: #af0d00;

//Transactions View
$amount-of-transaction: #b14891;
$value-of-transaction: #35a4e8;
$network-count-icon: #c4c4c4;
$tooltip-color: #494956;
$amount-of-transaction: #b14891;
$value-of-transaction: #35a4e8;
$amount-of-transaction: #b14891;
$value-of-transaction: #35a4e8;
$cybersecurity-color: #ffe600;
$dns-health-color: #35a4e8;
$network-security-color: #922b73;
$patching-cadence-color: #60e6e1;
$application-security-color: #724bc3;
$endpoint-security-color: #ff736a;
$violet-color: #714ac3;
$light-green: #27acaa;
$orange: #ff810a;
$red: #df362c;
$green: #34c668;
$black1: #3a3a3a;
$light-blue: #178be4;
$OTHERS_PIE_CHART_COLOR: #c4c4cd;
$acquisition-number-color: #57e188;
$acquisition-value-color: #bf5b14;
$light-gray-color: #e1e1e6;
$announced-bankruptcy: #34c868;
$consolidated-bankruptcy: #1777cf;
$dismissed-bankruptcy: #932c73;
$emerged-bankruptcy: #ff9831;
$liquidated-bankruptcy: #e1362c;
$corpGovSeries-activist: #34c868;
$engageManagement-activist: #1777cf;
$environment-activist: #932c73;
$financialMatters-activist: #ff9831;
$alert-triangle: #ff9831;
$financingBankruptcyReorganization-activist: #e1362c;
$legalMatters-activist: #b14891;
$maMatters-activist: #8ce8ad;
$meetingMatters-activist: #9c82d4;
$social-activist: #87d3f2;
$strategicMatters-activist: #bc2f00;
$shadowColor: #000000b3;
$volume-of-investments: #168736;
$value-of-investments: #35a4e8;
$volume-of-exits: #b9251c;
$value-of-exits: #35a4e8;
$no-of-companies: #57e287;
$avg-entry-value: #35a4e8;
$loader-overlay: #2625257a;
$black: #000000;
$blue-3: #188ce5;
$blue-4: #003f5c;
$orange-2: #ff736a;
$downloadbox-option-bgcolor: #2e2e3c;
$segment-color: #109090;
$industry-color: #ff736a;
// Ecosystem filter types colors
$filter-co-investor: #ad2ec5;
$filter-investment: #fcb20f;
$filter-partnership: #1577cf;
$filter-subsidiary: #0ccaac;
$smart-money-red: #f95d54;
$light-green-distress: #95b809;
:export {
  blue3: $blue-3;
  black: black;
  colorForegroundLight: $color-foreground-light;
  black1: $black1;
  white4: $white-4;
  yellow1: $yellow-1;
  white: $white;
  gray: $gray;
  gray2: $gray-2;
  alternategray: $alternate-gray;
  gray6: $gray-6;
  gray15: $gray-15;
  gray30: $gray-30;
  gray50: $gray-50;
  gray40: $gray-40;
  gray60: $gray-60;
  gray70: $gray-70;
  gray90: $gray-90;
  gray100: $gray-100;
  lightblack: $lightblack;
  darkblack: $dark-black;
  black3: $black-3;
  lightbrown: $light-brown;
  alternateblack: $alternateblack;
  formControlBlack: $form-control-black;
  lightgray: $lightgray;
  loaderOverlay: $loader-overlay;
  red2: $red-2;
  teal2: $teal-2;
  orange7: $orange-7;
  yellow: $yellow;
  yellow2: $yellow-2;
  green4: $green-4;
  darkwhite: $white-dark;
  lightwhite: $light-white;
  darkblack: $dark-black;
  headerGray: $header-gray;
  tooltipDetails: $tooltip-details;
  bgTile: $bg-tile;
  networkNodeUnchecked: $network-node-unchecked;
  networkNodeNa: $network-node-na;
  chartLineColor: $chart-line-color;
  tableCellPadding: $table-cell-padding;
  fontInterstate: $font-interstate;
  modalwidth: $modal-width;
  lsmodalwidth: $ls-modal-width;
  logoutModalWidth: $logout-modal-width;
  limitRestrictionModalWidth: $limit-restriction-modal-width;
  modalDeletePortfolio: $modal-delete-portfolio-width;
  downloadModalWidth: $download-modal-width;
  discardModalWidth: $discard-modal-width;
  avancedSearchClosingModalwidth: $advanced-search-closing-modal-width;
  saveReportModalWidth: $save-report-modal-width;
  deleteReportModalWidth: $delete-report-modal-width;
  EYItalic: $font-ey-NotoSansItalic;
  green2: $green-2;
  threatvaluecolors: {
    value0: $threat-value-0;
    value1: $threat-value-1;
    value2: $threat-value-2;
    value3: $threat-value-3;
    value4: $threat-value-4;
    value5: $threat-value-5;
  }

  heatMapvalue1: $heat-map-1;
  heatMapvalue2: $heat-map-2;
  heatMapvalue3: $heat-map-3;
  heatMapvalue4: $heat-map-4;
  heatMapvalue5: $heat-map-5;
  heatMapvalue6: $heat-map-6;

  revenueColor: $ebitdaCapexCreditRatingColor;
  ebitdaColor: $ebitda-color;
  ebitColor: $ebit-color;
  netIncomeColor: $net-income-color;
  cashColor: $cash-color;
  otherCurrentAssets: $other-current-assets;
  netPPColor: $net-PP-color;
  totalIntangiblesColor: $total-intangibles-color;
  otherLongTermAssetsColor: $other-long-term-assets-color;
  currentBorrowingsColor: $current-borrowings-color;
  otherCurrentLiabilitiesColor: $other-current-liabilities-color;
  LTDebtCapitalLeasesColor: $LT-debt-capital-leases-color;
  otherLongTermLiabilities: $other-long-term-liabilities;
  creditRatingCreditRatingColor: $ebitda-color;
  quickRatioColor: $quick-ratio-color;
  quickRatioCreditRatingColor: $quickRatioCreditRatingColor;
  solvencyCreditRatingColor: $solvencyCreditRatingColor;
  ebitdaCapexCreditRatingColor: $ebitdaCapexCreditRatingColor;
  currentAssetsColor: $current-assets-color;
  currentLiabilitiesColor: $current-liabilities-color;
  accountsReceivableColor: $accounts-receivable-color;
  inventoryColor: $inventory-color;
  tableCellPadding: $table-cell-padding;
  fontInterstate: $font-interstate;
  listMinimizedHeight: $list-minimized-height;
  stockPriceColor: $current-liabilities-color;
  fileUploadBackgroundColor: $file-upload-background-color;
  fileUploadErrorColor: $file-upload-error;
  fileUploadSuccessColor: $file-upload-success;
  fileUploadInProgressColor: $file-upload-inprogress;
  chartAxisLabelColor: $chart-axis-label-color;
  totalEquity: $total-Equity;
  filterIconColor: $filterIconColor;
  gray28: $gray-28;
  gray2e: $gray-2e;
  gray3A: $gray-3A;
  skyBlue: $skyBlue;
  black: $black;
  lightBlue: $light-blue;
  darkBlue: $dark-blue;
  white2: $white-2;
  brown2: $brown-2;
  blue2: $blue-2;
  estimatedPatentsStillActive: $estimated-patents-still-active;
  estimatedPatentsInActive: $estimated-patents-inactive;
  patentFilingsAlternateRowColor: $patent-filings-alternate-row-color;
  tableCellBorderColor: $gray-50;
  chartSolidLineColor: $chart-solid-line-color;
  mapMaxColor: $map-max-color;
  nonCountryJurisdictionLabelColor: $non-country-jurisdiction-label-color;
  gray80: $gray-80;
  black4: $black-4;
  white3: $white-3;
  gray5: $gray-5;
  keyword: $keyword;
  tableCellBackgroundHover: $table-cell-background-hover;
  gray90: $gray-90;
  valueOfTransactionsColor: $value-of-transaction;
  numberOfTransactionsColor: $amount-of-transaction;
  valueOfFundingColor: $orange;
  numberOfFundingColor: $purple;
  networkCountIcon: $network-count-icon;
  tooltipColor: $tooltip-color;
  dnsHealthColor: $dns-health-color;
  networkSecurityColor: $network-security-color;
  patchingCadenceColor: $patching-cadence-color;
  applicationSecurityColor: $application-security-color;
  endpointSecurityColor: $endpoint-security-color;
  cybersecurityColor: $cybersecurity-color;
  violetColor: $violet-color;
  lightGreen: $light-green;
  orange: $orange;
  red: $red;
  green: $green;
  lightBlue: $light-blue;
  OthersPieChartColor: $OTHERS_PIE_CHART_COLOR;
  acquisitionNumberColor: $acquisition-number-color;
  acquisitionValueColor: $acquisition-value-color;
  lightGrayColor: $light-gray-color;
  announcedBankruptcy: $announced-bankruptcy;
  consolidatedBankruptcy: $consolidated-bankruptcy;
  dismissedBankruptcy: $dismissed-bankruptcy;
  emergedBankruptcy: $emerged-bankruptcy;
  liquidatedBankruptcy: $liquidated-bankruptcy;
  shadowColor: $shadowColor;
  volumeOfInvestments: $volume-of-investments;
  valueOfInvestments: $value-of-investments;
  volumeOfExits: $volume-of-exits;
  valueOfExits: $value-of-exits;
  noOfCompanies: $no-of-companies;
  avgEntryValue: $avg-entry-value;
  unleveredFreeCashFlowMarginColor: $light-blue;
  unleveredFreeCashFlowColor: $threat-value-2;
  grossProfitColor: $grossProfitColor;
  valueOfDivestmentsColor: $yellow;
  numberOfDivestmentsColor: $grossProfitColor;
  publicOffering: $no-of-companies;
  spinSplit: $emerged-bankruptcy;
  mergerAcquisition: $liquidated-bankruptcy;
  lineChart: $blue-3;
  totalRevenueColor: $blue-4;
  grossProfitMarginColor: $grossProfitColor;
  EBITDAMarginColor: $orange-2;
  valueOfInvestmentColor: $value-of-investments;
  volumeOfInvestmentColor: $volume-of-investments;
  volumeOfExitsColor: $volume-of-exits;
  valueOfExitsColor: $value-of-exits;
  corpGov: $corpGovSeries-activist;
  engageManagement: $engageManagement-activist;
  environment: $environment-activist;
  financialMatters: $financialMatters-activist;
  financingBankruptcyReorganization: $financingBankruptcyReorganization-activist;
  legalMatters: $legalMatters-activist;
  maMatters: $maMatters-activist;
  meetingMatters: $meetingMatters-activist;
  social: $social-activist;
  strategicMatters: $strategicMatters-activist;
  downloadBoxOptionBgColor: $downloadbox-option-bgcolor;
  alertTriangle: $alert-triangle;
  volumenOfInvestments: $inventory-color;
  valueOfInvestments: $grossProfitColor;
  segmentColor: $segment-color;
  industryColor: $industry-color;
  smartMoneyRed: $smart-money-red;
  betaTextColor: $threat-value-5;
  lightGreenDistress: $light-green-distress;
  noOfPortfolioCompanies: $no-of-companies;
  averageInvestmentValue: $avg-entry-value;
  mergeracquisition: #ff6d00;
  undefinedinvestment: #e0362c;
  ipo: $other-long-term-liabilities;
  pipe: $purple;
  unattributedfundinground: #c4c4cd;
  postipoequity: #ff9831;
  angelseed: #b14891;
  privateequity: $net-PP-color;
  serieselate: #66c61c;
  growthequity: $red-2;
  seriesa: #feee95;
  buyoutlbo: $meetingMatters-activist;
  seriesb: #4ebeeb;
  seriesc: #a6ef67;
  secondarytransaction: $threat-value-3;
  seriesd: #2ed3b7;
  peNumberOfTransactions: $green;
  peValueOfTransactions: $value-of-transaction;
  fontEy: $font-ey;
}
