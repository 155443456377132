@import "../../styles/variables.module.scss";
@import "../../styles/mixins";
.options_wrapper {
  // width: 40px;
  // height: 25px;
  //reduced ellipsis section height
  width: 30px;
  height: 15px;
  right: 0;
  top: 0;

  flex-shrink: 0;
  border-radius: 1px;
  border: none;
  background: transparent;
  color: #1a1a24;
  position: absolute;
  //   right: -15px;
  //   top: -15px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
  &__dots {
    margin-bottom: 10px;
  }

  &__list {
    position: absolute;
    // top: 25px;
    top: 14px; //reduced ellipsis section height
    right: 0;
    width: 155px;
    background: white;
    color: #1a1a24;
    display: flex;
    flex-direction: column;
    gap: 5px;
    z-index: 5;

    & button {
      &:hover {
        background: #ccc;
      }

      text-align: left;
      background: transparent;
      border: none;
      color: #3a3a4a;
      font-family: EYInterstate;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 153.846% */
      text-transform: capitalize;
      cursor: pointer;
      padding: 8px 10px;
    }
  }
}
