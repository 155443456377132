@import "../../styles/variables.module.scss";
@import "../../styles/mixins";

@mixin custom-scrollbar-theme {
  &::-webkit-scrollbar {
    width: $custom-scrollbar-width;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray;
  }

  &::-webkit-scrollbar-button {
    height: 3px;
  }
}
.unstructure {
  background-image: url("../../assets/sebackground1.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #ccc;
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 1000;
  &__no-example {
    display: none;
  }
  &__example {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 95%;
    padding: 28px 0px 0px;
  }
  &__container-with-chat {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;
  }

  &__explore_btn {
    margin: 20px auto;
    display: flex;
    width: 17.8125rem;
    height: 4.8125rem;
    padding: 0.6875rem 2rem;
    justify-content: center;
    align-items: center;
    background: #ffe600;
    color: var(--grey-500, #2e2e38);
    text-align: center;
    font-family: EYInterstate;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
    cursor: pointer;
    border: none;
  }

  &__header {
    padding-left: 8px;
    padding-top: 8px;
    text-align: start;
    font-weight: bold;
    color: #fff;
  }

  &__shadow-container {
    margin: 22px;
    margin-top: -8px;
    margin-bottom: 5px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    // opacity: 0.7;
    background: #041509a3;
    justify-content: center;
    height: 460px;
    height: 100%;
    width: 90%;
    @media screen and (min-width: 1500px) {
      height: 100%;
    }
    @media screen and (min-width: 1600px) {
      height: 100%;
    }
  }
  &__scrollable-content {
    //padding-bottom: 100px;
    // overflow-y: scroll;
    // position: fixed;
  }
  &__shadow-container-wc {
    margin: 0px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    // opacity: 0.7;
    background: transparent;
    margin-top: 22px;
    justify-content: center;
    max-height: 430px;
    width: 90%;
  }

  &__title {
    color: #f6f6fa;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: toRem(58px);
    font-size: toRem(48px);
  }
  &__header-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 36px 70px 12px;
    gap: 16px;
    max-height: 95px;
  }
  &__header-container-with-chat {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px 60px 16px;
    gap: 16px;
    background: rgb(26 26 36 / 70%);
    display: none;
  }

  &__title-with-chat {
    color: #f6f6fa;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 2.5rem;
  }
  &__button {
    display: inline-flex;
    height: 3.75rem;
    padding: 0.6875rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.125rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    background: var(--primary-yellow, #ffe600);
    box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.4);
    border-width: 0;
  }
  &__button-with-chat {
    display: inline-flex;
    height: 3rem;
    border-radius: 0.625rem;
    padding: 0.6875rem 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.125rem;
    flex-shrink: 0;
    background: var(--primary-yellow, #ffe600);
    box-shadow: 0px 6px 25px 0px rgba(0, 0, 0, 0.4);
    border-width: 0;
  }
  &__button-title {
    color: var(--grey-500, #2e2e38);
    text-align: center;
    font-family: EYInterstate;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
    text-transform: capitalize;
  }
  &__with-button-title {
    color: var(--grey-500, #2e2e38);
    text-align: center;
    font-family: EYInterstate;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
    text-transform: capitalize;
  }
  &__bar-chat {
    width: toRem(32px);
    height: toRem(32px);
  }
  &__with-bar-chat {
    width: toRem(16px);
    height: toRem(16px);
  }
}

.hierarchy-box {
  .lazy-loading-load-indicator {
    text-align: center !important;
  }

  .hierarchy-checkboxes {
    height: 150px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 8px;
    margin-right: 5px;
    overflow: auto;
    font-size: 14px;
    background-color: #f6f6fa;
  }

  .hierarchy-checkboxes-empty {
    height: 8px;
    margin: 0;
    overflow: hidden;
  }
}
.react-checkbox-tree > ol ol .rct-node-leaf::before {
  position: absolute;
  left: -16px;
}
.react-checkbox-tree {
  &:not(.rct-native-display) {
    input {
      display: none;
    }
  }

  .rct-icon {
    font-family: "Font Awesome 5 Free", "FontAwesome", sans-serif;
    color: $gray-5;
  }

  &.rct-icons-fa5 {
    .rct-checkbox {
      .rct-icon {
        position: relative;
        top: 0px;
        width: 13px;
        height: 13px;
        display: inline-block;
        border: 1px solid $gray-5;
        font-family: "Font Awesome 5 Free", "FontAwesome", sans-serif;
      }

      .rct-icon-check {
        color: $black;
        border: 1px solid $gray-5;

        &::before {
          content: "\f00c";
          position: absolute;
          top: 2px;
          font-size: 11px;
          font-weight: 900;
          left: 1px;
        }
      }
    }
  }

  ol {
    padding-left: $as-tree-struct-left-padding;
    margin-bottom: 0;
    list-style: none;

    label,
    .rct-bare-label {
      color: $black;
    }

    .rct-bare-label {
      .rct-title {
        margin-left: 0;
      }
    }
  }

  > ol {
    padding-left: 0;

    ol {
      margin-left: 21px;
      padding-top: 6px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        height: 9px;
        left: -1px;
        width: 2px;
        bottom: 0;
      }

      li {
        position: relative;
      }
    }
  }

  ul {
    list-style: none;
  }

  .rct-node-parent {
    .rct-collapse-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      margin-right: 9px;
      border: none;
      line-height: 1;
      margin-top: 0;

      &:focus {
        outline: none;
      }

      span {
        font-size: 8px;
        color: $gray-10;
      }
      .fa-chevron-down {
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        color: $gray-50;
      }
      .fa-chevron-up {
        -webkit-transform: rotate(-180deg);
        -moz-transform: rotate(-180deg);
        -o-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
        transform: rotate(-180deg);
        color: $gray-50;
      }
    }

    .rct-node-parent {
      .rct-collapse-btn {
        margin-right: 3px;
      }
    }
  }

  .rct-text {
    display: flex;
    margin-bottom: 5px;

    > label {
      display: flex;
      margin-bottom: 0;
      cursor: pointer;
    }

    button {
      margin-top: 5px;
    }
  }

  .rct-title {
    margin-left: 10px;
    word-break: break-word;
    font-size: 12px;
    font-weight: 300;
  }
}

.custom-scrollbar {
  @include custom-scrollbar-theme;
}
.filter-footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px;
}
.hierarchy-search-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: "#f6f6fa";
  border-bottom: 1px solid $white;
  padding: 12px 12px 0px;

  i {
    font-size: 0.8125rem;
    color: $white;
    padding: 5px;
  }

  .filter-remove {
    font-size: 1rem;
    cursor: pointer;
  }

  .input {
    margin-right: 6px;
    font-size: 14px;
    padding-left: 5px;
    width: 100%;
    height: 24px;
    font-family: $font-interstate;
    padding-left: 5px;
    color: $black;

    &::placeholder {
      color: $black;
    }
  }
}

.se-close-icon {
  cursor: pointer;
}
.tableoverlapsector {
  z-index: 9999;
  left: 0;
}

.tableoverlapsector1 {
  width: 330px;
  background: #f6f6fa;
  border-radius: 7px;
  position: absolute;
  z-index: 9999;
  justify-content: center;
  left: 0;
}
.tableoverlapsector1-with-chat {
  width: 330px;
  background: #f6f6fa;
  border-radius: 7px;
  position: absolute;
  z-index: 9999;
  justify-content: center;
  left: 0;
}
.tableoverlapgeography {
  width: 330px;
  background: #f6f6fa;
  border-radius: 7px;
  position: absolute;
  z-index: 9999;
  left: 0;
}
.tableoverlapgeography-with-chat {
  position: absolute;
  z-index: 9999;
  width: 100%;
  justify-content: center;
  width: 330px;
  background: #f6f6fa;
  border-radius: 7px;
}

.tableoverlapgeograpyh1 {
  position: fixed;
  top: 34%;
  z-index: 9999;
  width: 100%;
  justify-content: center;
  margin-left: 5%;
  left: 350px;
  top: 18%;
}

.thin-white-line {
  // border-bottom: 0.3px solid white;
  // padding-bottom: 6px;
}
.footer-with-chat {
  position: fixed !important;
  bottom: 0 !important;
}
