@import "../../styles/variables.module.scss";

.suggested-queries {
  padding: 0px 12px 8px;
  background: transparent;
  border-radius: 4px;
  padding-bottom: 5px;

  .query {
    display: flex;
    padding: 3px 10px;
    margin-left: 15px;
    border-radius: 25px;
    border: 1px solid $alternate-gray;
    background: $gray;
    color: $white-dark;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
  }
}
